import React from "react"
import { Button, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { CustomTitle } from "../../../components/CustomText"
import { useStyles } from "./styles"

export default function UnsubscribeForm({ id }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid id={id} container justify={"center"} className={classes.container}>
      <CustomTitle title={t("unsubscribe")} />
      <Grid item xs={12} className={classes.description}>
        <Typography align={"center"} gutterBottom>
          {t("unsubscribeMessage")}
        </Typography>
      </Grid>
      <Grid item container xs={12} justify={"space-evenly"}>
        <Grid item>
          <Button variant={"contained"} className={classes.confirmButton}>
            {t("confirm")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant={"contained"} className={classes.cancelButton}>
            {t("cancel")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
