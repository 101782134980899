import { makeStyles } from "@material-ui/core"


export const useStyles = makeStyles(theme => ({
  container: {
    padding: "7rem 18.5rem 5rem",
    height: "100%",
    minHeight: "65vh"
  },
  description: {
    color: "black",
    padding: "2rem 5rem"
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    padding: '.5rem 2rem',
    fontWeight: 'bold',
    borderRadius: 10,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      borderColor: 'white',
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    padding: '.5rem 2rem',
    fontWeight: 'bold',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      // borderColor: 'white',
    },
  }
}))
