import React from 'react';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Layout/Seo';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UnsubscribeForm from '../sections/Unsubcribe/Form';

export default function UnsubscribePage({location}) {

  return (
    <Layout>
      <Seo title="Unsubscribe" />
      <Header location={location} />
      <UnsubscribeForm />
      <Footer />
    </Layout>
  );
}
